import React, { useState } from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import H2 from '../../components/simple/heading/H2.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

// import { Tip } from 'grommet'

function RiskAssessment (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)

  const submitForm = () => {
    props.submitForm()
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Behaviour/Risk Assessment Plan</H2>
          <Button label={<Text><FontAwesomeIcon color='#fe8300' icon={['fas', 'question-circle']} /></Text>} plain tip='This is the first page of the Behaviour Plan' />
        </Box>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {props.studentValues && questions.length
          ? (
            <Form
              //   validate='blur'
              onChange={nextValue => {
                props.setBehaviourPlanValues(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={props.behaviourPlanValues}
            >
              <ResponsiveGrid
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['auto', 'auto', 'auto', 'auto'],
                  large: ['auto', 'auto', 'auto', 'auto'],
                  xlarge: ['auto', 'auto', 'auto', 'auto']
                }}
                gap='small'
              >
              {/* <Grid
                rows={['auto', 'auto', 'auto', 'auto']}
                columns={['auto', 'auto', 'auto', 'auto']}
                gap='small'
                areas={[
                  { name: 'main', start: [0, 0], end: [1, 1] }
                ]}
              > */}
                {/* Student Name */}
                <FormField
                  label={questions[0].questionText}
                  name={questions[0].key}
                  required
                >
                  <Select
                    // id='student'
                    disabled={props.userId !== 'new'}
                    name={questions[0].key}
                    labelKey='name'
                    options={props.studentValues}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>

                {/* Date of Plan */}
                <FormField
                  label={questions[1].questionText}
                  name={questions[1].key}
                  required
                >
                  <TextInput
                    name={questions[1].key}
                    type='date'
                  />
                </FormField>

                {/* Review Date */}
                <FormField
                  label={questions[2].questionText}
                  name={questions[2].key}
                  required
                >
                  <TextInput
                    name={questions[2].key}
                    type='date'
                  />
                </FormField>

                {/* Completed By */}
                <FormField
                  label={questions[3].questionText}
                  name={questions[3].key}
                  required
                >
                  <Select
                    name={questions[3].key}
                    labelKey='name'
                    options={props.userValues}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>
              </ResponsiveGrid>

              <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

              <Box>
                {/* What should be done additonally */}
                <Text size='large' weight='bold'>Reasonable Adjustments</Text>
                <FormField
                  label={questions[4].questionText}
                  name={questions[4].key}
                  required
                >
                  <TextArea
                    name={questions[4].key}
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                <FormField
                  label={questions[5].questionText}
                  name={questions[5].key}
                  required
                >
                  <TextArea
                    name={questions[5].key}
                  />
                </FormField>

                <FormField
                  label={questions[6].questionText}
                  name={questions[6].key}
                  required
                >
                  <TextArea
                    name={questions[6].key}
                  />
                </FormField>

                <FormField
                  label={questions[7].questionText}
                  name={questions[7].key}
                  required
                >
                  <TextArea
                    name={questions[7].key}
                  />
                </FormField>
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default RiskAssessment
