import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Error handler
import useErrorHandler from '../utils/useErrorHandler'
import FlashMessage from '../utils/FlashMessage'
import { capitalize } from '../utils/text'

// Services
import FormService from '../services/FormService'
import LocalStorageService from '../services/LocalStorageService'
import OrganisationService from '../services/OrganisationService'

// Grommet
import { DocumentPdf, DocumentText, Folder, Image, Video } from 'grommet-icons'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import Text from '../components/simple/text/Text'
import H1 from '../components/simple/heading/H1'

// Appt Widgets
import CounterBox from '../components/compound/counterBox/CounterBox'
import ListBox from '../components/compound/listBox/ListBox'

function Dashboard (props) {
  const history = useHistory()
  const { error, showError } = useErrorHandler(null)

  const [apiToken, setApiToken, deleteApiToken] = useStore()
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  const [dashboardState, setDashboardState] = useState({
    users: '',
    students: '',
    incidents: ''
  })
  // NOTE: getUser and clearSession currently need to be called on every screen
  // this is definitely a violation of DRY and needs to be refactor

  // check if the user is logged in, if so restored the session
  // const getUser = () => {
  //   // check local storage
  //   var apiToken = LocalStorageService.get('apiToken')
  //   if (apiToken) {
  //     setApiToken(apiToken)
  //     // get currentOrgs
  //     const myOrganisations = LocalStorageService.get('currentOrgs')
  //     setCurrentOrgs(myOrganisations)
  //   } else {
  //     clearSession()
  //   }
  // }

  // // clear the user's session if they log out
  // const clearSession = () => {
  //   // remove all localstorage
  //   LocalStorageService.clear()

  //   // remove the session data
  //   deleteApiToken()
  //   deleteCurrentUser()
  //   deleteCurrentOrgs()
  //   history.push('/')
  // }

  const contentIcons = {
    pdf: <DocumentPdf size='medium' color='brand' />,
    text: <DocumentText size='medium' color='brand' />,
    category: <Folder size='medium' color='brand' />,
    image: <Image size='medium' color='brand' />,
    video: <Video size='medium' color='brand' />
  }

  // Get number of Users for this Organisation
  const getDashboardCounts = async () => {
    console.log('This is dash props', props)

    if (!activeOrg || !activeOrg.id || !apiToken) {
      // Temporary use of apiToken
      // const apiToken = LocalStorageService.get('apiToken')
      //
      const apiToken = LocalStorageService.get('apiToken')
      const activeOrg = LocalStorageService.get('activeOrg')
      const organisationId = activeOrg.id

      let params = {
        fields: 'id,email,firstName,lastName,type',
        orgId: activeOrg.id
      }

      const userCount = await OrganisationService.getUserCount(apiToken, params, organisationId)

      params = {
        fields: 'id,user,organisation,reference,type',
        limit: 1000,
        orgId: activeOrg.id
      }

      const where = {
        organisationId: organisationId
      }

      const incidentReportSubmissions = await FormService.getSubmissions(apiToken, params, where)

      userCount.incidents = 0
      if (incidentReportSubmissions?.page?.total) {
        userCount.incidents = incidentReportSubmissions.page.total
      }

      // const contentCount = await ContentService.getContentCount(apiToken, organisationId)
      // var mappedContent = null
      // if (contentCount !== 0) {
      //   const recentlyCreatedContent = await ContentService.getRecentlyCreatedContent(apiToken, organisationId)
      //   console.log(recentlyCreatedContent)
      //   mappedContent = recentlyCreatedContent.map((data, index) => ({
      //     icon: contentIcons[data.type],
      //     location: <Anchor href={`/content/${data.id}`} color='dark-1'>{data.title}</Anchor>
      //   }))
      // }

      setDashboardState(userCount)
    }
  }

  // const linkItems = [
  //   { icon: <Video size='medium' color='brand' />, location: <Anchor href='#' color='dark-1'>Thirsty Video</Anchor> },
  //   { icon: <Video size='medium' />, location: <Anchor href='#' color='dark-1'>Sorry Video</Anchor> },
  //   { icon: <Video size='medium' />, location: <Anchor href='#' color='dark-1'>Scared Video</Anchor> }
  // ]

  // Basically componentDidMount
  useEffect(() => {
    getDashboardCounts()
  }, [])

  useEffect(() => {
    getDashboardCounts()
  }, [props.activeOrg])

  return (
    <>
      <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>Dashboard </H1>
          {error &&
            <FlashMessage message={error} />}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box direction='row-responsive' gap='small' justify='between' margin={{ bottom: 'medium' }}>
        {/* <CounterBox counter={dashboardState.contentCount} iconColor='#52bc94' icon='plus-square'>Content Items Published</CounterBox> */}
        <CounterBox counter={dashboardState.students} iconColor='#52bc94' icon='graduation-cap'>{capitalize(props?.terms?.serviceUserPlural || 'Service Users')}</CounterBox>
        <CounterBox counter={dashboardState.users} iconColor='#52bc94' icon='user-friends'>Users</CounterBox>
        <CounterBox counter={0} iconColor='#52bc94' icon='user-friends'>Smart Plans</CounterBox>
        <CounterBox counter={dashboardState.incidents} iconColor='#52bc94' icon='exclamation-triangle'>Incidents</CounterBox>
      </Box>

      <Box direction='row-responsive' gap='medium'>
        <Box background='white' direction='column' gap='medium' flex='grow' pad='medium' round='small' width='medium'>
          <Text weight='bold'>Welcome to BehaviourSmart</Text>
          <Text>Behaviour Smart is a simple and effective way of recording and analysing behaviour in your service.</Text>
          <Text>For troubleshooting advice and support please see our YouTube channel.</Text>
          <Button href='https://www.youtube.com/channel/UC7T-fb0jGDSXFfJ0liMO24Q' label='Learn More' target='_blank' />
        </Box>
        <Box direction='row-responsive' gap='medium' width='medium'>
          {/* <ListBox data={['Core - Article', 'Core-Audio', 'Core - Category', 'Core - Image', 'Core - PDF', 'Core - Video', 'Core - Website Link']} heading='Enabled Modules' /> */}
          {/* dashboardState.recentContent &&
            <ListBox data={dashboardState.recentContent} primaryKey='location' secondaryKey='icon' heading='Recently Created Content' /> */}
        </Box>
      </Box>
    </>
  )
}

export default Dashboard
