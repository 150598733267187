// React
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

// Grommet
import { Grommet } from 'grommet'

// Appt
import Anchor from '../components/simple/anchor/Anchor.js'
import Box from '../components/simple/box/Box.js'
import Button from '../components/simple/button/Button.js'
import CheckBox from '../components/simple/input/CheckBox.js'
import Form from '../components/simple/form/Form.js'
import FormField from '../components/simple/formField/FormField.js'
import Text from '../components/simple/text/Text.js'
import TextInput from '../components/simple/input/TextInput.js'

// Services
import NotificationService from '../services/NotificationService'
import UserService from '../services/UserService'

// Theme
import { theme } from '../utils/theme'

import queryStringHandler from '../utils/queryStringHandler'

/**
 * `UserActivate` renders a User activation screen
 */
function UserActivate (props) {
  const history = useHistory()
  const location = useLocation()
  const queryStringVal = queryStringHandler(location.search)

  console.log(queryStringVal)

  const apiToken = queryStringVal.apiToken

  const [activateValues, setActivateValues] = useState({
    firstname: '',
    lastname: '',
    password: '',
    tandc: false
  })

  const onChange = (nextValue) => {
    setActivateValues(nextValue)
  }

  const onSubmit = async () => {
    if (!activateValues.tandc) {
      NotificationService.error('Please accept that you have read the terms and conditions.')
    } else {
      var activateStatus = await UserService.activateUser(apiToken, activateValues)

      // stop if the activation failed
      if (activateStatus.error) {
        NotificationService.error(activateStatus.error)
      } else {
        history.push('/login')
      }
    }
  }

  const goTandC = () => {
    alert('Go to T&Cs')
  }

  const onTandCChange = () => {
    return null
  }

  return (
    <Grommet background={{ image: 'url(/flytt-background.png)' }} full theme={theme}>
      <Box align='center' height='100%' justify='center'>
        <Box align='center' animation='fadeIn' border='all' pad='large' alignSelf='center' width='medium'>
          <Box>
            {/* <Image fit='contain' src={this.props.image} /> */}
            <Text margin={{ top: '30px', bottom: '10px' }} textAlign='start' weight='bold'>You have been invited to join the BehaviourSmart app.</Text>
          </Box>

          <Box>
            <Text margin={{ top: '10px', bottom: '30px' }} size='small' textAlign='start'>To accept the invitation and create your account, enter your name and a chosen password below.</Text>
          </Box>

          <Box width='medium'>
            <Form
              value={activateValues}
              onChange={(nextValue) => onChange(nextValue)}
              onSubmit={() => onSubmit()}
            >
              <FormField label='First Name:' name='firstname' required>
                <TextInput name='firstname' type='text' placeholder='First Name' />
              </FormField>

              <FormField label='Last Name:' name='lastname' required>
                <TextInput name='lastname' type='text' placeholder='Last Name' />
              </FormField>

              <FormField label='Password:' name='password' required>
                <TextInput name='password' type='password' placeholder='Password' />
              </FormField>

              <FormField name='tandc'>
                <CheckBox label={<Text size='small'>I accept the <Anchor onClick={() => goTandC()} size='small' label='Terms & Conditions' /></Text>} name='tandc' onChange={onTandCChange} />
              </FormField>

              <Button fill='horizontal' label='Accept the invitation' primary type='submit' />
            </Form>
          </Box>
        </Box>
      </Box>
    </Grommet>)
}

UserActivate.propTypes = {
  /**
   * The function called when the back button has been selected
   */
  // back: PropTypes.func,
  /**
   * The function called when submitting the form
   */
  submit: PropTypes.func
}

export default UserActivate
