import ApiService from './ApiService'

class UserService {
  inviteUser = async (apiToken, data) => {
    var url = '/api/user/invite'

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {}

    var options = {
      email: [data.email],
      role: data.role,
      organisation: data.organisation
    }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:inviteUser ', result)
    return result
  }

  createUser = async (apiToken, options) => {
    var url = '/api/user'

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {}

    // var options = {
    //   data
    // }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:createUser ', result)
    return result
  }

  getUser = async (apiToken, params, userId) => {
    var url = `/api/user/${userId}`

    try {
      var user = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:getUser ', user)
    return user
  }

  getUsers = async (apiToken, params) => {
    var url = '/api/user'

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName,reference,ethnicity,gender,createdAt',
    //   limit: 1000
    // }

    // if (where) {
    //   params.where = where
    // }

    try {
      var users = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:getUsers ', users)
    return users
  }

  updateUser = async (apiToken, data, type = 'user') => {
    const userId = data.id
    var url = `/api/user/${userId}`

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {
      type
    }

    var options = {
      data: data
    }
    // var options = {
    //   data: {
    //     email: data.email,
    //     firstName: data.firstName,
    //     lastName: data.lastName,
    //     reference: data.reference,
    //     // dob: data.dob,
    //     ethnicity: data.ethnicity,
    //     genedr: data.gender
    //   }
    // }

    try {
      var result = await ApiService.put(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:updateUser ', result)
    return result
  }

  deleteUser = async (apiToken, data) => {
    const userId = data.id
    var url = `/api/user/${userId}`

    var params = {}

    var options = {}
    try {
      var result = await ApiService.sendDelete(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:deleteUser ', result)
    return result
  }

  activateUser = async (apiToken, data) => {
    console.log(apiToken, data)
    return {}
  }
}
export default new UserService()
