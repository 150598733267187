// React
import React from 'react'
import PropTypes from 'prop-types'

// Appt
import Box from '../../simple/box/Box'
import RadioButtonGroup from '../../simple/input/RadioButtonGroup'
import Text from '../../simple/text/Text'

/**
 * `OptionButtons` renders a Yes and No button group
 */
function OptionButtons (props) {
  const valueArray = []

  const buildValueArray = () => {
    let i = props.minRange

    while (i <= props.maxRange) {
      valueArray[i] = i
      i = i + props.step
    }
  }

  buildValueArray()

  return (
    <RadioButtonGroup
      direction='row'
      {...props}
    >
      {(option, { checked, hover }) => {
        let background
        if (checked) background = 'brand'
        else if (hover) background = 'light-4'
        else background = 'light-2'
        return (
          <Box background={background} pad='small'>
            {option}
          </Box>
        )
      }}
    </RadioButtonGroup>)
}

OptionButtons.defaultProps = {
  options: ['Yes', 'No']
}

OptionButtons.propTypes = {
  /**
   * group name
   */
  name: PropTypes.string
}

export default OptionButtons
