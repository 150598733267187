// react
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Appt
import Box from '../../simple/box/Box'
import Button from '../../simple/button/Button'
import Layer from '../../simple/layer/Layer'
import Text from '../../simple/text/Text'

import { FormClose } from 'grommet-icons'

/**
 * `NotificationLayer` renders a specific Layer
 */
function NotificationLayer (props) {
  const [open, setOpen] = useState()

  const onOpen = () => setOpen(true)

  const onClose = () => setOpen(undefined)

  const button1Click = () => {
    onClose()
    props.button1Click()
  }

  const button2Click = () => {
    onClose()
    props.button2Click()
  }

  useEffect(() => {
    onOpen()
  }, [])

  return (
    <>
      {open && (
        <Layer
          position={props.position}
          //   modal={false}
          margin={{ vertical: 'medium', horizontal: 'small' }}
          onClickOutside={() => { onClose() }}
          onEsc={() => { onClose() }}
          responsive={false}
          plain
        >
          <Box
            align='center'
            direction='row'
            gap='small'
            justify='between'
            round='small'
            elevation='medium'
            pad={{ vertical: 'xsmall', horizontal: 'small' }}
            background={props.status ? `status-${props.status}` : 'status-ok'}
          >
            <Box align='center' direction='row' gap='xsmall'>
              <Text>{props.children}</Text>
              {props.button1Text &&
                // eslint-disable-next-line react/jsx-handler-names
                <Button label={props.button1Text} onClick={() => { button1Click() }} primary />}
              {props.button2Text &&
                // eslint-disable-next-line react/jsx-handler-names
                <Button label={props.button2Text} onClick={() => { button2Click() }} secondary />}
            </Box>
            <Button icon={<FormClose />} onClick={() => { onClose() }} plain />
          </Box>
        </Layer>
      )}
    </>
  )
}

NotificationLayer.propTypes = {
  /**
   * Position of the Layer  
   *  
   * "bottom"  
   * "bottom-left"  
   * "bottom-right"  
   * **"center"**  
   * "end"  
   * "hidden"  
   * "left"  
   * "right"  
   * "start"  
   * "top"  
   * "top-left"  
   * "top-right"

   */
  position: PropTypes.string,
  /**
   * Notification status - determines the colour of the layer  
   *   
   * "error"  
   * **"ok"**  
   * "warning"
   */
  status: PropTypes.string
}

export default NotificationLayer
