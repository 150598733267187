import React, { useState } from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import Grid from '../../components/simple/grid/Grid.js'
import H2 from '../../components/simple/heading/H2.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'

function BehaviourDetails (props) {
  const [questions, setQuestions] = useState(props.questions)

  // Submit form values
  const submitForm = async (values) => {
    props.submitForm(values)
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >
        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Behaviour Details</H2>
          <Button label={<Text><FontAwesomeIcon color='#fe8300' icon={['fas', 'question-circle']} /></Text>} plain tip='This is the Behaviour Details page of the Incident Report' />
        </Box>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            props.setBehaviourPlanValues(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue)
          }}
          value={props.behaviourPlanValues}
        >
          <Box>
            <Text weight='bold'>In 5 words or less</Text>
          </Box>
          <ResponsiveGrid
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['auto', 'auto', 'auto', 'auto'],
              large: ['auto', 'auto', 'auto', 'auto'],
              xlarge: ['auto', 'auto', 'auto', 'auto']
            }}
            gap='small'
          >
          {/* <Grid
            rows={['auto', 'auto', 'auto', 'auto']}
            columns={['auto', 'auto', 'auto', 'auto']}
            gap='small'
            areas={[
              { name: 'main', start: [0, 0], end: [1, 1] }
            ]}
          > */}

            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[0].questionText}</Text>
                </Box>)}
              name={questions[0].key}
              required
            >
              <TextArea
                name={questions[0].key}
              />
            </FormField>

            <FormField
              label={questions[1].questionText}
              name={questions[1].key}
              required
            >
              <TextArea
                name={questions[1].key}
              />
            </FormField>

            <FormField
              label={questions[2].questionText}
              name={questions[2].key}
              required
            >
              <TextArea
                name={questions[2].key}
              />
            </FormField>

            <FormField
              label={questions[3].questionText}
              name={questions[3].key}
              required
            >
              <TextArea
                name={questions[3].key}
              />
            </FormField>

            <FormField
              label={questions[4].questionText}
              name={questions[4].key}
              required
            >
              <TextArea
                name={questions[4].key}
              />
            </FormField>

            <FormField
              label={questions[5].questionText}
              name={questions[5].key}
              required
            >
              <TextArea
                name={questions[5].key}
              />
            </FormField>

            <FormField
              label={questions[6].questionText}
              name={questions[6].key}
              required
            >
              <TextArea
                name={questions[6].key}
              />
            </FormField>

            <FormField
              label={questions[7].questionText}
              name={questions[7].key}
            >
              <TextArea
                name={questions[7].key}
              />
            </FormField>

            <FormField
              label={questions[8].questionText}
              name={questions[8].key}
            >
              <TextArea
                name={questions[8].key}
              />
            </FormField>

            <FormField
              label={questions[9].questionText}
              name={questions[9].key}
            >
              <TextArea
                name={questions[9].key}
              />
            </FormField>

            <FormField
              label={questions[10].questionText}
              name={questions[10].key}
            >
              <TextArea
                name={questions[10].key}
              />
            </FormField>

            <FormField
              label={questions[11].questionText}
              name={questions[11].key}
            >
              <TextArea
                name={questions[11].key}
              />
            </FormField>
          </ResponsiveGrid>

        </Form>
      </Box>
    </Box>
  )
}

export default BehaviourDetails
