import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import LocalStorageService from '../services/LocalStorageService'
import OrganisationTagService from '../services/OrganisationTagService'
import NotificationService from '../services/NotificationService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import CheckBox from '../components/simple/input/CheckBox'
import DataTable from '../components/simple/dataTable/DataTable.js'
import Divider from '../components/simple/divider/Divider'
import Form from '../components/simple/form/Form.js'
import FormField from '../components/simple/formField/FormField.js'
import Grid from '../components/simple/grid/Grid'
import H1 from '../components/simple/heading/H1'
import Select from '../components/simple/input/Select'
import Text from '../components/simple/text/Text'
import TextInput from '../components/simple/input/TextInput'

// Shared Components
import BreadcrumbBar from './shared/BreadcrumbBar'

function ServiceSetup () {
  const history = useHistory()

  const [behaviourLeads, setBehaviourLead] = useState([])
  const [behaviourLeadAddButton, setBehaviourLeadAddButton] = useState(false)

  const [deescalationTechniques, setDeescalationTechnique] = useState([])
  const [deescalationAddButton, setDeescalationAddButton] = useState(false)

  const [physicalTechniques, setPhysicalTechnique] = useState([])
  const [physicalAddButton, setPhysicalAddButton] = useState(false)
  // const [deescalationChecked, setDeescalationChecked] = useState([])

  const allAcademicYearStartDays = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
  const allAcademicYearStartMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const allAcademicYears = ['Year -10', 'Year -9', 'Year -8', 'Year -7', 'Year -6', 'Year -5', 'Year -4', 'Year -3', 'Year -2', 'Year -1', 'Year 0', 'Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5', 'Year 6', 'Year 7', 'Year 8', 'Year 9', 'Year 10', 'Year 11', 'Year 12', 'Year 13', 'Year 14', 'Year 15', 'Year 16', 'Year 17', 'Year 18', 'Year 19', 'Year 20']
  // const [selectedAcademicYear, setSelectedAcademicYear] = useState([])

  // const onRemoveAcademicYear = year => {
  //   const yearIndex = allAcademicYears.indexOf(year)
  //   setSelectedAcademicYear(
  //     selectedAcademicYear.filter(selectedAcademicYear => selectedAcademicYear !== yearIndex)
  //   )
  // }

  // const renderAcademicYear = year => (
  //   <Button
  //     key={`year_tag_${year}`}
  //     href='#'
  //     onClick={event => {
  //       event.preventDefault()
  //       event.stopPropagation()
  //       onRemoveAcademicYear(year)
  //     }}
  //     onFocus={event => event.stopPropagation()}
  //   >
  //     <Box
  //       align='center'
  //       direction='row'
  //       gap='xsmall'
  //       pad={{ vertical: 'xsmall', horizontal: 'small' }}
  //       margin='xsmall'
  //       background='accent-1'
  //       round='large'
  //     >
  //       <Text size='small' color='white'>
  //         {year}
  //       </Text>
  //       <Box background='white' round='full' margin={{ left: 'xsmall' }}>
  //         <FormClose
  //           color='accent-1'
  //           size='small'
  //           style={{ width: '12px', height: '12px' }}
  //         />
  //       </Box>
  //     </Box>
  //   </Button>
  // )

  // const renderOption = (option, state) => (
  //   <Box pad='small' background={state.active ? 'active' : undefined}>
  //     {option}
  //   </Box>
  // )

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  // Initialize userDetails otherwise we get error
  // A component is changing an uncontrolled input of type text to be controlled
  const [serviceDetails, setServiceDetails] = useState({
    serviceUserTerm: '',
    serviceUserTermPlural: '',
    serviceTerm: '',
    serviceTermPlural: '',
    academicYearStartDay: '',
    academicYearStartMonth: '',
    enabledAcademicYears: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    deescalationTechnique: '',
    physicalTechnique: ''
  })

  // Submit form values
  // ToDo: Add error checking and loading spinner
  const submitForm = async (values) => {
    console.log('submitForm ', values)

    const tagsToSubmit = []

    // Set up tags for Service Defaults
    const serviceDefaultsToSubmit = ['serviceUserTerm', 'serviceUserTermPlural', 'serviceTerm', 'serviceTermPlural', 'academicYearStartDay', 'academicYearStartMonth', 'enabledAcademicYears']

    serviceDefaultsToSubmit.forEach(field => {
      tagsToSubmit.push({
        organisation: activeOrg.id,
        key: field,
        type: 'besmart',
        value: field === 'enabledAcademicYears' ? JSON.stringify(values[field]) : values[field]
      })
    })

    // And Behaviour Leads if there are any
    if (behaviourLeads.length > 0) {
      const leads = []

      behaviourLeads.forEach(lead => {
        leads.push(lead)
      })

      tagsToSubmit.push({
        organisation: activeOrg.id,
        key: 'behaviourLeads',
        type: 'besmart',
        value: JSON.stringify(leads)
      })
    }

    // And Deescalation Techniques if there are any
    if (deescalationTechniques.length > 0) {
      const techniques = []

      deescalationTechniques.forEach(technique => {
        techniques.push(technique)
      })

      tagsToSubmit.push({
        organisation: activeOrg.id,
        key: 'deescalationTechniques',
        type: 'besmart',
        value: JSON.stringify(techniques)
      })
    }

    // And Physical Techniques if there are any
    if (physicalTechniques.length > 0) {
      const techniques = []

      physicalTechniques.forEach(technique => {
        techniques.push(technique)
      })

      tagsToSubmit.push({
        organisation: activeOrg.id,
        key: 'physicalTechniques',
        type: 'besmart',
        value: JSON.stringify(techniques)
      })
    }

    const params = {
      orgId: activeOrg.id
    }

    const tagSet = await OrganisationTagService.set(apiToken, params, tagsToSubmit)

    console.log('tag set ', tagSet)
    // if (userId === 'new') {
    //   var userAdded = await UserService.inviteUser(apiToken, values)
    //   if (userAdded.error) {
    //     showError('Error saving the user, please try again - ', userAdded.error)
    //   } else {
    //     // How do we send the message to /users ?
    //     // showError(userAdded.data.message)
    //     history.push('/users', { message: userAdded.data.message })
    //   }
    // } else {
    //   var userUpdated = await UserService.updateUser(apiToken, values)
    //   if (userUpdated.error) {
    //     showError('Error saving the user, please try again - ', userUpdated.error)
    //   } else {
    //     // showError('User updated successfully', userAdded)
    //     history.push('/users', { message: 'User updated successfully' })
    //   }
    // }
    NotificationService.success('Your changes have been saved')
  }

  const addLead = () => {
    // Don't save if all fields empty
    if (serviceDetails.firstName !== '' || serviceDetails.lastName !== '' || serviceDetails.phone !== '' || serviceDetails.email !== '') {
      const lead = {
        firstName: serviceDetails.firstName,
        lastName: serviceDetails.lastName,
        phone: serviceDetails.phone,
        email: serviceDetails.email
      }
      setBehaviourLead(behaviourLeads => [...behaviourLeads, lead])

      setServiceDetails(serviceDetails => ({
        ...serviceDetails,
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
      }))
      // setBehaviourLeadAddButton(false)
    }
  }

  const removeLead = (data) => {
    const newLeads = behaviourLeads.filter((item) => item.email !== data.email)

    setBehaviourLead(newLeads)

    console.log('deleting lead')
  }

  const deescalationTechniqueSelect = (technique) => {
    setDeescalationTechnique(
      deescalationTechniques.map(item =>
        item.technique === technique
          ? { ...item, selected: !item.selected }
          : item
      ))
  }

  const addDeescalationTechnique = () => {
    const techniqueExists = deescalationTechniques.find((item, index) => {
      return (item.technique === serviceDetails.deescalationTechnique)
    })

    if (techniqueExists) {
      NotificationService.error('Deescalation technique already exists, please add a different one')
    } else {
      // Add technique and set to selected
      const technique = {
        technique: serviceDetails.deescalationTechnique,
        selected: true
      }
      setDeescalationTechnique(deescalationTechniques => [...deescalationTechniques, technique])

      // Clear input
      setServiceDetails(serviceDetails => ({
        ...serviceDetails,
        deescalationTechnique: ''
      }))

      // Disable Add button
      setDeescalationAddButton(false)
    }
  }

  const physicalTechniqueSelect = (technique) => {
    setPhysicalTechnique(
      physicalTechniques.map(item =>
        item.technique === technique
          ? { ...item, selected: !item.selected }
          : item
      ))
  }

  const addPhysicalTechnique = () => {
    const techniqueExists = physicalTechniques.find((item, index) => {
      return (item.technique === serviceDetails.physicalTechnique)
    })

    if (techniqueExists) {
      NotificationService.error('Physical technique already exists, please add a different one')
    } else {
      // Add technique and set to selected
      const technique = {
        technique: serviceDetails.physicalTechnique,
        selected: true
      }
      setPhysicalTechnique(physicalTechniques => [...physicalTechniques, technique])

      // Clear input
      setServiceDetails(serviceDetails => ({
        ...serviceDetails,
        physicalTechnique: ''
      }))

      // Disable Add button
      setPhysicalAddButton(false)
    }
  }

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    let unmounted = false

    const getServiceSetup = async () => {
      const params = {
        fields: 'type,key,value',
        limit: 100,
        orgId: activeOrg.id
      }

      const where = {
        type: 'besmart'
      }

      const organisationTags = await OrganisationTagService.get(apiToken, params, where)

      // ToDo: Add error checking and loading spinner
      if (!unmounted) {
        console.log(organisationTags)

        const tags = {}
        let leads = []
        let deescalationTechniques = []
        let physicalTechniques = []

        if (organisationTags) {
          organisationTags.forEach(tag => {
            switch (tag.key) {
              case 'serviceUserTerm':
                tags.serviceUserTerm = tag.value
                break
              case 'serviceUserTermPlural':
                tags.serviceUserTermPlural = tag.value
                break
              case 'serviceTerm':
                tags.serviceTerm = tag.value
                break
              case 'serviceTermPlural':
                tags.serviceTermPlural = tag.value
                break
              case 'academicYearStartDay':
                tags.academicYearStartDay = tag.value
                break
              case 'academicYearStartMonth':
                tags.academicYearStartMonth = tag.value
                break
              case 'enabledAcademicYears':
                try {
                  tags.enabledAcademicYears = JSON.parse(tag.value)
                } catch (err) {
                  console.error(err)
                }
                break
              case 'behaviourLeads':
                try {
                  leads = JSON.parse(tag.value)
                } catch (err) {
                  console.error(err)
                }
                break
              case 'deescalationTechniques':
                try {
                  deescalationTechniques = JSON.parse(tag.value)
                } catch (err) {
                  console.error(err)
                }
                break
              case 'physicalTechniques':
                try {
                  physicalTechniques = JSON.parse(tag.value)
                } catch (err) {
                  console.error(err)
                }
                break
            }
          })
        }

        setServiceDetails(serviceDetails => ({
          ...serviceDetails,
          serviceUserTerm: tags.serviceUserTerm,
          serviceUserTermPlural: tags.serviceUserTermPlural,
          serviceTerm: tags.serviceTerm,
          serviceTermPlural: tags.serviceTermPlural,
          academicYearStartDay: tags.academicYearStartDay,
          academicYearStartMonth: tags.academicYearStartMonth,
          enabledAcademicYears: tags.enabledAcademicYears
        }))
        setBehaviourLead(leads)
        setDeescalationTechnique(deescalationTechniques)
        setPhysicalTechnique(physicalTechniques)
      }
    }

    getServiceSetup()

    return () => { unmounted = true }
  }, [])

  // Behaviour Leads have been altered
  // so rebuild the display list
  const [mappedLeads, setMappedLeads] = useState([])
  const [leadColumns, setLeadDataColumns] = useState()

  useEffect(() => {
    setLeadDataColumns([
      {
        property: 'firstName',
        header: <Text pad='none' margin='none'>First Name</Text>
      },
      {
        property: 'lastName',
        header: <Text>Last Name</Text>
      },
      {
        property: 'phone',
        header: <Text>Phone</Text>
      },
      {
        property: 'email',
        header: <Text>Email</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesomeIcon icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeLead(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedLeads = behaviourLeads.map((lead, index) => ({
      firstName: lead.firstName,
      lastName: lead.lastName,
      phone: lead.phone,
      email: lead.email
    }))

    setMappedLeads(mappedLeads)
  }, [behaviourLeads])

  return (
    <Box>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Service Setup
      </BreadcrumbBar>

      {/*
            <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
<Box direction='column' pad={{ horizontal: 'medium' }}>
        <H1 margin={{ top: 'small', bottom: 'none' }}>Service Setup</H1>
        <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
      </Box>
            </Box>
*/}


      {!serviceDetails?.serviceTerm && (
        <Box background='white' direction='column' gap='small' round='small' flex='grow' margin={{bottom: 'medium'}}>
          <Box
            gap='small'
            margin={{ horizontal: 'small' }}
            pad='small'
            round='small'
          >
            <Text margin={{ top: 'xsmall' }} size='large'>Setup to Continue</Text>
            <Text margin={{ bottom: 'xsmall' }}>Please complete the following information before using BehaviourSmart.</Text>

          </Box>
        </Box>
      )}

      <Box background='white' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Form
              onChange={nextValue => {
                // Enable Behaviour Lead Add button if all fields have data
                // setBehaviourLeadAddButton(nextValue.firstName !== '' && nextValue.lastName !== '' && nextValue.phone !== '' && nextValue.email !== '')

                // Enable Deescalation Technique Add button if field has data
                setDeescalationAddButton(nextValue.deescalationTechnique)
                // Enable Physical Technique Add button if field has data
                setPhysicalAddButton(nextValue.physicalTechnique)
                setServiceDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={serviceDetails}
            >
              <Text margin={{ top: 'xsmall' }} size='large'>Preferred Terms</Text>
              <Box direction='column'>
                <Box direction='row-responsive' gap='medium'>

                  <FormField
                    label='Service (singular)'
                    name='serviceTerm'
                    required
                  >
                    <TextInput
                      name='serviceTerm'
                      placeholder='service'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Service (plural)'
                    name='serviceTermPlural'
                    required
                  >
                    <TextInput
                      name='serviceTermPlural'
                      placeholder='services'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Service User (singular)'
                    name='serviceUserTerm'
                    required
                  >
                    <TextInput
                      name='serviceUserTerm'
                      placeholder='service user'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Service Users (plural)'
                    name='serviceUserTermPlural'
                    required
                  >
                    <TextInput
                      name='serviceUserTermPlural'
                      placeholder='service users'
                      type='text'
                    />
                  </FormField>

                </Box>

                <Divider color='primary' margin={{ vertical: 'small' }} />

                <Text margin={{ top: 'xsmall' }} size='large'>Term Dates</Text>
                <Box direction='row-responsive' gap='medium'>
                  <FormField
                    label='Start Day'
                    name='academicYearStartDay'
                    required
                  >
                    <Select
                      name='academicYearStartDay'
                      options={allAcademicYearStartDays}
                    />
                  </FormField>

                  <FormField
                    label='Start Month'
                    name='academicYearStartMonth'
                    required
                  >
                    <Select
                      name='academicYearStartMonth'
                      options={allAcademicYearStartMonths}
                    />
                  </FormField>

                  <FormField
                    label='Years Covered'
                    name='enabledAcademicYears'
                    required
                  >
                    <Select
                      closeOnChange={false}
                      multiple
                      name='enabledAcademicYears'
                      options={allAcademicYears}
                    />
                  </FormField>
                </Box>
              </Box>

              <Divider color='primary' margin={{ vertical: 'small' }} />
              <Text margin={{ top: 'xsmall' }} size='large'>Behaviour Leads</Text>
              <Box direction='row-responsive' gap='medium'>
                <DataTable
                  columns={leadColumns}
                  data={mappedLeads}
                  fill='horizontal'
                  size='large'
                />
              </Box>
              <Box direction='row-responsive' gap='medium'>
                <FormField
                  label='First Name'
                  name='firstName'
                >
                  <TextInput
                    name='firstName'
                    type='text'
                  />
                </FormField>

                <FormField
                  label='Last Name'
                  name='lastName'
                >
                  <TextInput
                    name='lastName'
                    type='text'
                  />
                </FormField>

                <FormField
                  label='Phone Number'
                  name='phone'
                >
                  <TextInput
                    name='phone'
                    type='text'
                  />
                </FormField>

                <FormField
                  label='Email'
                  name='email'
                >
                  <TextInput
                    name='email'
                    type='email'
                  />
                </FormField>

                {/* <Button disabled={!behaviourLeadAddButton} label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /></Text>} color='primary' onClick={() => addLead()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} /> */}
                <Button label={<Text>Add</Text>} color='primary' onClick={() => addLead()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ vertical: 'small' }} />
              <Text margin={{ top: 'xsmall', bottom: 'medium' }} size='large'>Behaviour Management Techniques</Text>

              <Box direction='row-responsive' gap='medium' pad={{ top: 'small' }}>
                <Box direction='column' width='50%'>
                  <Text size='small'>Deescalation Techniques</Text>
                  {deescalationTechniques.length > 0 &&

                    <Grid columns={{ count: 2, size: 'auto' }}>
                      {deescalationTechniques.map((technique, index, techniques) => {
                        return (
                          <Box key={index} margin={{ vertical: 'xsmall' }}>
                            <CheckBox checked={deescalationTechniques[index].selected} label={<Text size='small'>{techniques[index].technique}</Text>} onChange={() => deescalationTechniqueSelect(techniques[index].technique)} />
                          </Box>)
                      })}
                    </Grid>}
                  <Box direction='row' gap='small'>
                    <FormField
                      name='deescalationTechnique'
                    >
                      <TextInput
                        name='deescalationTechnique'
                        placeholder='new technique'
                        type='text'
                      />
                    </FormField>

                    <Button label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> Add</Text>} disabled={!deescalationAddButton} color='primary' onClick={() => addDeescalationTechnique()} style={{ height: '35px' }} />
                  </Box>
                </Box>

                <Box direction='column' width='50%'>
                  <Text size='small'>Physical Intervention Techniques</Text>
                  {physicalTechniques.length > 0 &&

                    <Grid columns={{ count: 2, size: 'auto' }}>
                      {physicalTechniques.map((technique, index, techniques) => {
                        return (
                          <Box key={index} margin={{ vertical: 'xsmall' }}>
                            <CheckBox checked={physicalTechniques[index].selected} label={<Text size='small'>{techniques[index].technique}</Text>} onChange={() => physicalTechniqueSelect(techniques[index].technique)} />
                          </Box>)
                      })}
                    </Grid>}
                  <Box direction='row' gap='small'>
                    <FormField
                      name='physicalTechnique'
                    >
                      <TextInput
                        name='physicalTechnique'
                        placeholder='new technique'
                        type='text'
                      />
                    </FormField>

                    <Button label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> Add</Text>} disabled={!physicalAddButton} onClick={() => addPhysicalTechnique()} color='primary' style={{ height: '35px' }} />
                  </Box>
                </Box>
              </Box>

              <Divider color='primary' margin={{ vertical: 'small' }} />
              <Text margin={{ top: 'xsmall', bottom: 'medium' }} size='large'>Subscription Management</Text>

              <Box direction='row' gap='medium' pad={{ top: 'small' }}>
                <Button label='Subscription Management' onClick={() => history.push('/service/subscription')} />
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ vertical: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ServiceSetup
