import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

import FormService from '../../../services/FormService'
import LocalStorageService from '../../../services/LocalStorageService'
import NotificationService from '../../../services/NotificationService'
import UserGroupService from '../../../services/UserGroupService'
import UserTagService from '../../../services/UserTagService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../../components/simple/anchor/Anchor'
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import Divider from '../../../components/simple/divider/Divider'
import FieldBox from '../shared/FieldBox.js'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import IncidentSearchResults from './IncidentSearchResults.js'
import JoinBox from '../shared/JoinBox.js'
import OperatorBox from '../shared/OperatorBox.js'
import Text from '../../../components/simple/text/Text'
import TextInput from '../../../components/simple/input/TextInput'

// Shared Components
import BreadcrumbBar from '../../shared/BreadcrumbBar'

function IncidentSearch (props) {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')

  const [criteriaIndex, setCriteriaIndex] = useState(0)

  const [fieldTypes, setFieldTypes] = useState([])

  const [query, setQuery] = useState()
  const [queryResults, setQueryResults] = useState()

  const goBack = () => {
    history.goBack()
  }

  // If a different field has been selected then
  // set the field type of the field selected
  // so that the Operator dropdown can be
  // populated accordingly
  const handleFieldChanged = (fieldName, value) => {
    const fieldIndex = fieldName.substring(fieldName.indexOf('-') + 1)
    const oldFieldTypes = fieldTypes

    oldFieldTypes[fieldIndex] = value.type

    setFieldTypes(oldFieldTypes)
  }

  const criteriaRow = (index, addJoin) => {
    return (
      <>
        <Box direction='row-responsive' gap='medium'>
          <FormField
            direction='column'
            label='Field'
            name={`Field-${index}`}
          >
            <FieldBox
              handleFieldChanged={handleFieldChanged}
              name={`Field-${index}`}
            />
          </FormField>

          <FormField
            direction='column'
            label='Query'
            name={`Query-${index}`}
          >
            <OperatorBox
              name={`Query-${index}`}
            />
          </FormField>

          <FormField
            direction='column'
            label='Value'
            name={`Value-${index}`}
          >
            <TextInput
              name={`Value-${index}`}
              type='text'
            />
          </FormField>
        </Box>

        {addJoin &&
          <Box direction='row-responsive' gap='medium'>
            <FormField
              direction='column'
              label='Join'
              name={`Join-${index}`}
            >
              <JoinBox
                name={`Join-${index}`}
              />
            </FormField>
          </Box>}
      </>

    )
  }

  const buildCriteria = () => {
    const criteriaTable = []

    for (let i = 0; i <= criteriaIndex; i++) {
      criteriaTable.push(criteriaRow(i, (criteriaIndex > 0 && i !== criteriaIndex)))
    }

    return criteriaTable
  }

  const addCriteria = () => {
    setCriteriaIndex(criteriaIndex + 1)
  }

  const buildQuery = () => {
    let resultsQuery = {}
    // let resultsQuery = '{'
    // Loop through query criteria
    // Note the query criteria will not be sorted in order
    // hence doing this

    // console.log(query)

    let endOfQuery = false
    let queryCounter = 0
    const queryArray = []

    // skip the query if nothing's submitted
    // if (queryArray.length) {
    //   endOfQuery = true
    // }

    // and add them to an array
    while (!endOfQuery) {
      queryArray.push({
        field: query[`Field-${queryCounter}`],
        query: query[`Query-${queryCounter}`],
        value: query[`Value-${queryCounter}`],
        join: query[`Join-${queryCounter}`]
      })
      queryCounter++
      var checkNextCriteria = `Field-${queryCounter}` in query

      if (!checkNextCriteria) {
        endOfQuery = true
      }
    }

    console.log(queryArray)

    // queryArray.map(item => (
    //   switch (item.query) {
    //     case 'equal to':
    //       resultsQuery += item.field + ': "' + item.value + '"'
    //       break
    //   }
    // ))
    queryArray.forEach((item) => {
      switch (item.query) {
        case 'is':
          // resultsQuery += item.field + ': "' + item.value + '"'
          resultsQuery.questionKey = item.field.key
          resultsQuery.response = item.value
          break
      }

      if (item.join === 'AND') {
        resultsQuery += ', '
      }
    })

    // resultsQuery += '}'
    console.log(resultsQuery)

    const where = resultsQuery

    console.log('WHERE ', where)

    const results = getResults(where)
    console.log(results)
    // for (const x in query) {
    //   console.log(`${x}: ${query[x]}`)
    // }
  }

  const getResults = async (where) => {
    const params = {
      fields: 'id,user,organisation,submission,questionKey,questionText,response',
      limit: 1000
    }

    const results = await FormService.getResponses(apiToken, params, where)

    setQueryResults(results.data)
  }

  // Basically componentDidMount
  useEffect(() => {
    // let unmounted = false

    // Check if adding a new User or editing an existing one
    // const getUserDetails = async (userId) => {
    //   const apiToken = LocalStorageService.get('apiToken')

    //   const userDetails = await UserService.getUser(apiToken, userId)

    //   if (!unmounted) {
    //     setUserDetails(userDetails.data[0])
    //     // console.log(userDetails.data)
    //   }
    // }

    // if (userId !== 'new') {
    //   getUserDetails(userId)
    //   getContacts()
    // }

    // return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Incident Search
      </BreadcrumbBar>
      {/* <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Incident Search</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box> */}

      <Box background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='small'>
            <Box direction='row-responsive' justify='between' pad={{ horizontal: 'none', vertical: 'small' }}>
              <Text margin={{ top: 'xsmall' }} size='large'>Search Criteria</Text>
              <Box alignSelf='center' direction='row'>
                <Button disabled onClick={() => alert('coming soon')} label={<Text><FontAwesomeIcon icon={['fal', 'cloud-upload']} /> Add to Quick Search</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
              </Box>
            </Box>

            <Form
              //   validate='blur'
              onChange={nextValue => {
                console.log(nextValue)
                setQuery(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                // console.log(nextValue)
                buildQuery(nextValue)
              }}
              // value={organisationDetails}
            >
              <Box direction='column' gap='xsmall' margin={{ bottom: 'small' }}>
                {buildCriteria()}
                {/* <Button icon={<AddCircle size='medium' />} label='Add Criteria' color='primary' onClick={() => addCriteria()} style={{ height: '35px' }} alignSelf='start' margin={{ bottom: 'small' }} /> */}
                <Button label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> Add Criteria</Text>} color='primary' onClick={() => addCriteria()} style={{ height: '35px' }} alignSelf='start' margin={{ bottom: 'small' }} />
              </Box>
              <Divider />
              <Box direction='column' gap='xsmall' margin={{ top: 'small' }}>
                {queryResults?.length &&
                  <IncidentSearchResults
                    results={queryResults}
                  />}
                {!queryResults?.length && <Text color='#666'>- No results to display -</Text>}
              </Box>

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='View Results' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default IncidentSearch
