import React, { useEffect, useState } from 'react'

// Spinner
// import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../components/simple/box/Box'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import Grid from '../../components/simple/grid/Grid.js'
import H2 from '../../components/simple/heading/H2.js'
import RadioButtonGroup from '../../components/simple/input/RadioButtonGroup'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text.js'
import TextArea from '../../components/simple/input/TextArea.js'

function BehaviourTechniques (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [deescalationTechniques, setDeescalationTechniques] = useState()
  const [physicalTechniques, setPhysicalTechniques] = useState()

  const submitForm = () => {
    props.submitForm()
  }

  const buildForm = () => {
    const techniques = ['deescalationTechniques', 'physicalTechniques']

    let filteredQuestions = null

    // Build array of technique questions
    techniques.forEach((tech) => {
      const questionList = []

      filteredQuestions = questions.filter(function (el) {
        return el.type === tech
      })

      // Loop round the two technique types
      // to get the questions for each type
      filteredQuestions.forEach((item, index) => {
        questionList.push(
          <>
            <FormField
              key={index}
              label={item.questionText}
              name={item.key}
            >
              <RadioButtonGroup
                direction='row'
                id={item.key}
                name={item.key}
                // onChange={event =>
                //   setActionButton(event.target.value)}
                options={['Try', 'Avoid']}
              >
                {(option, { checked, hover }) => {
                  let background
                  if (checked) background = 'brand'
                  else if (hover) background = 'light-4'
                  else background = 'light-2'
                  return (
                    <Box background={background} pad='small'>
                      {option}
                    </Box>
                  )
                }}
              </RadioButtonGroup>
            </FormField>

            <FormField
              label='Notes'
              name={`${item.key}-notes`}
            >
              <TextArea
                name={`${item.key}-notes`}
              />
            </FormField>
          </>
        )
      })
      if (tech === 'deescalationTechniques') {
        setDeescalationTechniques(questionList)
      } else {
        setPhysicalTechniques(questionList)
      }
    })

    // return questionList
    // }
  }

  useEffect(() => {
    const techniques = buildForm()
    console.log('techx', techniques)
  }, [])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <H2 margin={{ vertical: 'none' }}>Behaviour Management Techniques</H2>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            props.setBehaviourPlanValues(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue)
          }}
          value={props.behaviourPlanValues}
        >
          {/* <ResponsiveGrid
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['auto', 'auto'],
              large: ['auto', 'auto'],
              xlarge: ['auto', 'auto']
            }}
            gap='small'
            areas={[
              { name: 'main', start: [0, 0], end: [1, 1] }
            ]}
          > */}
            <Box>
              <Text size='large' weight='bold'>Deescalation Techniques</Text>
              <Grid
                rows={['auto', 'auto']}
                columns={['auto', 'auto']}
                gap='small'
                areas={[
                  { name: 'main', start: [0, 0], end: [1, 1] }
                ]}
              >
                {deescalationTechniques}
              </Grid>
            </Box>

            <Box>
              <Text size='large' weight='bold'>Physical Techniques</Text>
              <Grid
                rows={['auto', 'auto']}
                columns={['auto', 'auto']}
                gap='small'
                areas={[
                  { name: 'main', start: [0, 0], end: [1, 1] }
                ]}
              >
                {physicalTechniques}
              </Grid>
            </Box>
          {/* </ResponsiveGrid> */}
          {/* <Box
            direction='row'
          >
            <Box
              direction='row-responsive'
            >
              <Box
                direction='column'
              >
                <Text>Deescalation Techniques</Text>
                <Box
                  direction='row'
                >
                  <FormField
                    label={questions[0].questionText}
                    name={questions[0].key}
                  >
                    <RadioButtonGroup
                      direction='row'
                      id={questions[0].key}
                      name={questions[0].key}
                      // onChange={event =>
                      //   setActionButton(event.target.value)}
                      options={['Try', 'Avoid']}
                    >
                      {(option, { checked, hover }) => {
                        let background
                        if (checked) background = 'brand'
                        else if (hover) background = 'light-4'
                        else background = 'light-2'
                        return (
                          <Box background={background} pad='small'>
                            {option}
                          </Box>
                        )
                      }}
                    </RadioButtonGroup>
                  </FormField>

                  <FormField
                    label={questions[1].questionText}
                    name={questions[1].key}
                  >
                    <TextArea
                      name={questions[1].key}
                    />
                  </FormField>
                </Box>

                <Box
                  direction='row'
                >
                  <FormField
                    label={questions[2].questionText}
                    name={questions[2].key}
                  >
                    <RadioButtonGroup
                      direction='row'
                      id={questions[2].key}
                      name={questions[2].key}
                      // onChange={event =>
                      //   setActionButton(event.target.value)}
                      options={['Try', 'Avoid']}
                    >
                      {(option, { checked, hover }) => {
                        let background
                        if (checked) background = 'brand'
                        else if (hover) background = 'light-4'
                        else background = 'light-2'
                        return (
                          <Box background={background} pad='small'>
                            {option}
                          </Box>
                        )
                      }}
                    </RadioButtonGroup>
                  </FormField>

                  <FormField
                    label={questions[3].questionText}
                    name={questions[3].key}
                  >
                    <TextArea
                      name={questions[3].key}
                    />
                  </FormField>
                </Box>

                <Box
                  direction='row'
                >
                  <FormField
                    label={questions[4].questionText}
                    name={questions[4].key}
                  >
                    <RadioButtonGroup
                      direction='row'
                      id={questions[4].key}
                      name={questions[4].key}
                      // onChange={event =>
                      //   setActionButton(event.target.value)}
                      options={['Try', 'Avoid']}
                    >
                      {(option, { checked, hover }) => {
                        let background
                        if (checked) background = 'brand'
                        else if (hover) background = 'light-4'
                        else background = 'light-2'
                        return (
                          <Box background={background} pad='small'>
                            {option}
                          </Box>
                        )
                      }}
                    </RadioButtonGroup>
                  </FormField>

                  <FormField
                    label={questions[5].questionText}
                    name={questions[5].key}
                  >
                    <TextArea
                      name={questions[5].key}
                    />
                  </FormField>
                </Box>

                <Box
                  direction='row'
                >
                  <FormField
                    label={questions[6].questionText}
                    name={questions[6].key}
                  >
                    <RadioButtonGroup
                      direction='row'
                      id={questions[6].key}
                      name={questions[6].key}
                      // onChange={event =>
                      //   setActionButton(event.target.value)}
                      options={['Try', 'Avoid']}
                    >
                      {(option, { checked, hover }) => {
                        let background
                        if (checked) background = 'brand'
                        else if (hover) background = 'light-4'
                        else background = 'light-2'
                        return (
                          <Box background={background} pad='small'>
                            {option}
                          </Box>
                        )
                      }}
                    </RadioButtonGroup>
                  </FormField>

                  <FormField
                    label={questions[7].questionText}
                    name={questions[7].key}
                  >
                    <TextArea
                      name={questions[7].key}
                    />
                  </FormField>
                </Box>

                <Box
                  direction='row'
                >
                  <FormField
                    label={questions[8].questionText}
                    id={questions[8].key}
                    name={questions[8].key}
                  >
                    <RadioButtonGroup
                      direction='row'
                      name={questions[8].key}
                      // onChange={event =>
                      //   setActionButton(event.target.value)}
                      options={['Try', 'Avoid']}
                    >
                      {(option, { checked, hover }) => {
                        let background
                        if (checked) background = 'brand'
                        else if (hover) background = 'light-4'
                        else background = 'light-2'
                        return (
                          <Box background={background} pad='small'>
                            {option}
                          </Box>
                        )
                      }}
                    </RadioButtonGroup>
                  </FormField>

                  <FormField
                    label={questions[9].questionText}
                    name={questions[9].key}
                  >
                    <TextArea
                      name={questions[9].key}
                    />
                  </FormField>
                </Box>
              </Box>

              <Box
                direction='column'
              >
                <Text>Physical Techniques</Text>
                <Box
                  direction='row'
                >
                  <FormField
                    label={questions[10].questionText}
                    name={questions[10].key}
                  >
                    <RadioButtonGroup
                      direction='row'
                      id={questions[10].key}
                      name={questions[10].key}
                      // onChange={event =>
                      //   setActionButton(event.target.value)}
                      options={['Try', 'Avoid']}
                    >
                      {(option, { checked, hover }) => {
                        let background
                        if (checked) background = 'brand'
                        else if (hover) background = 'light-4'
                        else background = 'light-2'
                        return (
                          <Box background={background} pad='small'>
                            {option}
                          </Box>
                        )
                      }}
                    </RadioButtonGroup>
                  </FormField>

                  <FormField
                    label={questions[11].questionText}
                    name={questions[11].key}
                  >
                    <TextArea
                      name={questions[11].key}
                    />
                  </FormField>
                </Box>

                <Box
                  direction='row'
                >
                  <FormField
                    label={questions[12].questionText}
                    name={questions[12].key}
                  >
                    <RadioButtonGroup
                      direction='row'
                      id={questions[12].key}
                      name={questions[12].key}
                      // onChange={event =>
                      //   setActionButton(event.target.value)}
                      options={['Try', 'Avoid']}
                    >
                      {(option, { checked, hover }) => {
                        let background
                        if (checked) background = 'brand'
                        else if (hover) background = 'light-4'
                        else background = 'light-2'
                        return (
                          <Box background={background} pad='small'>
                            {option}
                          </Box>
                        )
                      }}
                    </RadioButtonGroup>
                  </FormField>

                  <FormField
                    label={questions[13].questionText}
                    name={questions[13].key}
                  >
                    <TextArea
                      name={questions[13].key}
                    />
                  </FormField>
                </Box>

                <Box
                  direction='row'
                >
                  <FormField
                    label={questions[14].questionText}
                    name={questions[14].key}
                  >
                    <RadioButtonGroup
                      direction='row'
                      id={questions[14].key}
                      name={questions[14].key}
                      // onChange={event =>
                      //   setActionButton(event.target.value)}
                      options={['Try', 'Avoid']}
                    >
                      {(option, { checked, hover }) => {
                        let background
                        if (checked) background = 'brand'
                        else if (hover) background = 'light-4'
                        else background = 'light-2'
                        return (
                          <Box background={background} pad='small'>
                            {option}
                          </Box>
                        )
                      }}
                    </RadioButtonGroup>
                  </FormField>

                  <FormField
                    label={questions[15].questionText}
                    name={questions[15].key}
                  >
                    <TextArea
                      name={questions[15].key}
                    />
                  </FormField>
                </Box>

                <Box
                  direction='row'
                >
                  <FormField
                    label={questions[16].questionText}
                    name={questions[16].key}
                  >
                    <RadioButtonGroup
                      direction='row'
                      id={questions[16].key}
                      name={questions[16].key}
                      // onChange={event =>
                      //   setActionButton(event.target.value)}
                      options={['Try', 'Avoid']}
                    >
                      {(option, { checked, hover }) => {
                        let background
                        if (checked) background = 'brand'
                        else if (hover) background = 'light-4'
                        else background = 'light-2'
                        return (
                          <Box background={background} pad='small'>
                            {option}
                          </Box>
                        )
                      }}
                    </RadioButtonGroup>
                  </FormField>

                  <FormField
                    label={questions[17].questionText}
                    name={questions[17].key}
                  >
                    <TextArea
                      name={questions[17].key}
                    />
                  </FormField>
                </Box>

                <Box
                  direction='row'
                >
                  <FormField
                    label={questions[18].questionText}
                    name={questions[18].key}
                  >
                    <RadioButtonGroup
                      direction='row'
                      id={questions[18].key}
                      name={questions[18].key}
                      // onChange={event =>
                      //   setActionButton(event.target.value)}
                      options={['Try', 'Avoid']}
                    >
                      {(option, { checked, hover }) => {
                        let background
                        if (checked) background = 'brand'
                        else if (hover) background = 'light-4'
                        else background = 'light-2'
                        return (
                          <Box background={background} pad='small'>
                            {option}
                          </Box>
                        )
                      }}
                    </RadioButtonGroup>
                  </FormField>

                  <FormField
                    label={questions[19].questionText}
                    name={questions[19].key}
                  >
                    <TextArea
                      name={questions[19].key}
                    />
                  </FormField>
                </Box>
              </Box>
            </Box>
          </Box> */}
        </Form>
      </Box>
    </Box>
  )
}
export default BehaviourTechniques
