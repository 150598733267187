import React, { useState } from 'react'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import H2 from '../../components/simple/heading/H2.js'
import RatingButtonGroup from '../../components/compound/ratingButtonGroup/RatingButtonGroup'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea.js'

function DeescalationTechniques (props) {
  const [questions, setQuestions] = useState(props.questions)

  const submitForm = () => {
    props.submitForm()
  }

  const buildForm = () => {
    const questionList = []
    questions.forEach((item, index) => {
      if (index !== questions.length - 1) {
        questionList.push(
          <FormField
            key={index}
            label={questions[index].questionText}
            name={questions[index].key}
          >
            <RatingButtonGroup
              id={questions[index].key}
              name={questions[index].key}
              minRange={1}
              maxRange={10}
              step={1}
            />
          </FormField>

        )
      } else {
        if (props.incidentValues.otherTechniques) {
          questionList.push(
            <FormField
              key={index}
              label={questions[index].questionText}
              name={questions[index].key}
            >
              <TextArea
                name={questions[index].key}
              />
            </FormField>
          )
        }
      }
    })

    return questionList
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>De-escalation Techniques used and effectiveness</H2>
          <Button
            label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
            plain
            tip={{
              content: (
                <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                  <Text>Please record the effectiveness of any de-escalation techniques. De-escalation techniques should also be used throughout the intervention.</Text>
                </Box>),
              // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
            }}
          />
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            props.setIncidentValues(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue)
          }}
          value={props.incidentValues}
        >
          <Box direction='row'>
            <Text margin={{ right: 'small' }}>1=Not Effective 10=Very Effective</Text>
            <Button
              label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
              plain
              tip={{
                content: (
                  <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                    <Text>In your opinion how effective was the de-escalation techniques used.</Text>
                  </Box>),
                // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
              }}
            />
          </Box>

          <ResponsiveGrid
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['auto', 'auto'],
              large: ['auto', 'auto'],
              xlarge: ['auto', 'auto']
            }}
            gap='small'
          >
            {/*
          <Grid
            rows={['auto', 'auto']}
            columns={['auto', 'auto']}
            gap='small'
            areas={[
              { name: 'main', start: [0, 0], end: [1, 1] }
            ]}
          > */}
            {buildForm()}
            {/* <FormField
              label={questions[0].questionText}
              name={questions[0].key}
            >
              <RatingButtonGroup
                id={questions[0].key}
                name={questions[0].key}
                minRange={1}
                maxRange={10}
                step={1}
              />
            </FormField>

            <FormField
              label={questions[1].questionText}
              name={questions[1].key}
            >
              <RatingButtonGroup
                id={questions[1].key}
                name={questions[1].key}
              />
            </FormField>

            <FormField
              label={questions[2].questionText}
              name={questions[2].key}
            >
              <RatingButtonGroup
                id={questions[2].key}
                name={questions[2].key}
              />
            </FormField>

            <FormField
              label={questions[3].questionText}
              name={questions[3].key}
            >
              <RatingButtonGroup
                id={questions[3].key}
                name={questions[3].key}
              />
            </FormField>

            <FormField
              label={questions[4].questionText}
              name={questions[4].key}
            >
              <RatingButtonGroup
                id={questions[4].key}
                name={questions[4].key}
              />
            </FormField>

            <FormField
              label={questions[5].questionText}
              name={questions[5].key}
            >
              <RatingButtonGroup
                id={questions[5].key}
                name={questions[5].key}
              />
            </FormField>

            <FormField
              label={questions[6].questionText}
              name={questions[6].key}
            >
              <RatingButtonGroup
                id={questions[6].key}
                name={questions[6].key}
              />
            </FormField>

            <FormField
              label={questions[7].questionText}
              name={questions[7].key}
            >
              <RatingButtonGroup
                id={questions[7].key}
                name={questions[7].key}
              />

            </FormField>

            <FormField
              label={questions[8].questionText}
              name={questions[8].key}
            >
              <RatingButtonGroup
                id={questions[8].key}
                name={questions[8].key}
              />
            </FormField>

            {props?.incidentValues?.otherTechniques &&
              <FormField
                label={questions[9].questionText}
                name={questions[9].key}
              >
                <TextArea
                  name={questions[9].key}
                />
              </FormField>} */}

          </ResponsiveGrid>
        </Form>
      </Box>
    </Box>
  )
}
export default DeescalationTechniques
