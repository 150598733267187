// React
import React from 'react'
import PropTypes from 'prop-types'

// Bootstrap
// import Container from 'react-bootstrap/Container'
// import Form from 'react-bootstrap/Form'

// Appt
import Anchor from '../../simple/anchor/Anchor.js'
import Box from '../../simple/box/Box.js'
import Button from '../../simple/button/Button.js'
import Form from '../../simple/form/Form.js'
import FormField from '../../simple/formField/FormField.js'
import Text from '../../simple/text/Text.js'
import TextInput from '../../simple/input/TextInput.js'

/**
 * `Login` renders a Login screen
 */
class Login extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loginValues: {
        username: '',
        password: ''
      }
    }
  }

  render () {
    const handleBackClick = () => this.props.onBackClick()

    const handleForgotPasswordClick = () => this.props.onForgotPasswordClick()

    // const [value, setValue] = useState({ name: 'a', username: 'b' });

    const onChange = (nextValue) => {
      this.setState({ loginValues: nextValue })
    }

    const onSubmit = () => {
      this.props.onSignInClick(this.state.loginValues)
    }

    return (
      <>
        <Box align='center' animation='fadeIn' pad={{ horizontal: '0' }} alignSelf='center' width='medium'>
          <Box width='medium' >
            <Text color={this.props.labelColor} margin={{ top: 'medium', bottom: 'medium' }} textAlign={this.props.title.center ? 'center' : 'start'} weight='bold' size='26px'>{this.props.title.label}</Text>
          </Box>

          <Box width='medium' border={{ color: 'brand', side: 'bottom' }}>
            <Form
              value={this.state.loginValues}
              onChange={(nextValue) => onChange(nextValue)}
              onSubmit={() => onSubmit()}
            >
              <FormField direction={this.props.username.position === 'inline' ? 'row' : 'column'} label={<Text size='medium' >{this.props.username.label}</Text>} style={{
                color: this.props.labelColor }}  name='username'  required>
                <TextInput name='username' type={this.props.username.type} style={{padding: {vertical: '-10px'}}} placeholder={this.props.username.label || 'Username'} />
              </FormField>

              <FormField direction={this.props.password.position === 'inline' ? 'row' : 'column'} label={<Text size='medium' >{this.props.password.label}</Text>} style={{ color: this.props.labelColor }} name='password' required>
                <TextInput name='password' type='password' placeholder={this.props.password.label || 'Password'} />
              </FormField>


              <Box direction='row' justify={this.props.forgotPassword.position} margin={{ top: 'xsmall' }}>
                {/* {this.props.onBackClick &&
                  <Anchor onClick={() => handleBackClick()} size='xsmall' label={this.props.back.label} />} */}
                <Anchor onClick={() => handleForgotPasswordClick()} size='xsmall' label={this.props.forgotPassword.label} color={this.props.labelColor} />
              </Box>

              <Box direction='row' justify={this.props.submit.position} style={{ anchor: { textDecoration: 'underline'}}} margin={{ top: 'small', bottom: 'medium' }}>
                <Button fill={this.props.submit.size === 'large' ? 'horizontal' : false } label={this.props.submit.label} primary type='submit' />
              </Box>


            </Form>
          </Box>
        </Box>
      </>)
  }
}

Login.defaultProps = {
  // back: {
  //   label: '<Back'
  // },
  forgotPassword: {
    label: 'Forgot Password?',
    position: 'end'
  },
  labelColor: '#000',
  password: {
    label: '',
    position: ''
  },
  submit: {
    label: 'sign in',
    position: '',
    size: 'large'
  },
  title: {
    label: 'Login',
    center: true
  },
  username: {
    label: '',
    position: '',
    type: 'email'
  }
}

Login.propTypes = {
  /**
   * The back button
   */
  // back: PropTypes.shape({
  //   label: PropTypes.string
  // }),
  /**
   * The forgot password button
   *
   *  - label - the label text
   *  - position - start, center, **end**
   */
  forgotPassword: PropTypes.shape({
    label: PropTypes.string,
    position: PropTypes.string
  }),
  /**
   * The image at the top of the Login screen
   */
  image: PropTypes.string,
  /**
   * The color of the labels
   */
  labelColor: PropTypes.string,
  /**
   * The function called on clicking the Back link.
   * If there is no Back link then back will not be displayed
   */
  onBackClick: PropTypes.func,
  /**
   * The function called on clicking the Forgot Password link
   */
  onForgotPasswordClick: PropTypes.func,
  /**
   * The function called on signing in
   */
  onSignInClick: PropTypes.func,
  /**
   * Information about the Password input box
   *  - label - the label text. Omit for no label
   *  - position - is the label inline with input or **above**
   */
  password: PropTypes.shape({
    label: PropTypes.string,
    position: PropTypes.string
  }),
  /**
   * The submit button
   */
  submit: PropTypes.shape({
    label: PropTypes.string,
    position: PropTypes.string,
    size: PropTypes.string
  }),
  /**
   * The heading above the login form
   *
   * label - the title
   * center - if false will be left aligned
   */
  title: PropTypes.shape({
    label: PropTypes.string,
    center: PropTypes.bool
  }),
  /**
   * Information about the Username input box
   *  - label - the label text. Omit for no label
   *  - position - is the label inline with input or **above**
   *  - type - text or **email**
   */
  username: PropTypes.shape({
    label: PropTypes.string,
    position: PropTypes.string,
    type: PropTypes.string
  })
}

export default Login
