import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Grommet
import { ResponsiveContext } from 'grommet'

import Moment from 'react-moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import ApiService from '../services/ApiService'
import LocalStorageService from '../services/LocalStorageService'
import NotificationService from '../services/NotificationService'
import UserService from '../services/UserService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import DataTable from '../components/simple/dataTable/DataTable.js'
import Text from '../components/simple/text/Text'
import H1 from '../components/simple/heading/H1'

import NotificationLayer from '../components/compound/notification/NotificationLayer'

// Shared Components
import BreadcrumbBar from './shared/BreadcrumbBar'

function UserList (props) {
  const [userValues, setUserValues] = useState([])
  const [showDeleteOptions, showDelete] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const activeOrg = LocalStorageService.get('activeOrg')

  const [sort, setSort] = useState({
    property: 'name',
    direction: 'asc'
  })

  // Prompt user to delete
  const showDeleteUserOptions = (user) => {
    setUserToDelete(user)
    showDelete(true)
  }

  // Delete a User
  const deleteUser = async () => {
    const apiToken = LocalStorageService.get('apiToken')
    // Delete from server
    const response = await UserService.deleteUser(apiToken, userToDelete)
    if (response.data.success === 'true') {
      // And from state to force a refresh
      const newUserValues = userValues.filter((item) => item.id !== userToDelete.id)
      setUserValues(newUserValues)
      NotificationService.info('User deleted')
    } else {
      NotificationService.error(response.error)
    }
    showDelete(false)
  }

  // Reset User password
  const resetPassword = async (email) => {
    try {
      var passwordResetStatus = await ApiService.resetPassword(null, email)
    } catch (err) {
      NotificationService.error('Password reset error - ', err)
      console.log(err)
      return
    }

    NotificationService.error('Password reset email has been sent')
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get Users for this organisation
    const getUsers = async () => {
      const apiToken = LocalStorageService.get('apiToken')

      var params = {
        fields: 'id,firstName,lastName,email,userName,reference,ethnicity,gender,createdAt',
        limit: 1000,
        orgId: activeOrg.id
      }

      params.where = {
        organisation: props.activeOrg.id,
        type: 'user'
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              name: data.firstName + ' ' + data.lastName,
              email: data.email,
              created: <Moment format='MMM Do YYYY-HH:mm'>{data.createdAt}</Moment>
            }))
            setUserValues(mappedUsers)
          }
        }
      }
    }

    getUsers()
    setLoading(false)

    return () => { unmounted = true }
  }, [])

  // Refresh if active Org changes
  useEffect(() => {
    // TODO: DRY - How do we do this ?
    // Get Users for this organisation
    const getUsers = async () => {
      const apiToken = LocalStorageService.get('apiToken')

      var params = {
        fields: 'id,firstName,lastName,email,userName,reference,ethnicity,gender,createdAt',
        limit: 1000,
        orgId: activeOrg.id,
        type: 'user'
      }

      params.where = {
        // organisation: props.activeOrg.id,
        // type: 'user'
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (users?.data) {
          const mappedUsers = users.data.map((data, index) => ({
            id: data.id,
            name: data.firstName + ' ' + data.lastName,
            email: data.email,
            created: data.createdAt
          }))
          setUserValues(mappedUsers)
        }
      }
    }

    getUsers()
    setLoading(false)
  }, [props.activeOrg])

  const userColumns = [
    {
      property: 'name',
      header: <Text weight={500}>Name</Text>
    },
    {
      property: 'email',
      header: <Text weight={500}>Email</Text>
    },
    {
      property: 'created',
      header: <Text weight={500}>Created</Text>
    },
    {
      property: 'actions',
      header: <Text weight={500}>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          <Button
            onClick={() => resetPassword(datum.email)}
            target='_self'
            plain
            children={
              <Box pad='5px'>
                <FontAwesomeIcon icon={['fal', 'key']} color='#fe8300' />
                {/* <License color='primary' size='medium' /> */}
              </Box>
            }
          />
          <Button
            href={`/user/${datum.id}/edit`}
            target='_self'
            plain
            children={
              <Box pad='5px'>
                <FontAwesomeIcon icon={['fal', 'edit']} color='#fe8300' />
                {/* <FormEdit color='primary' size='medium' /> */}
              </Box>
            }
          />

          <Button
            target='_self'
            plain
            onClick={() => showDeleteUserOptions(datum)}
            children={
              <Box pad='5px'>
                <FontAwesomeIcon icon={['fal', 'trash']} color='#fe8300' />
                {/* <FormTrash color='primary' size='medium' /> */}
              </Box>
            }
          />
        </Box>
      )
    }
  ]

  return (
    <>
      {showDeleteOptions &&
        <NotificationLayer button1Text='Yes' button1Click={deleteUser} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to delete user {userToDelete.name}?</NotificationLayer>}

      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        System Users
      </BreadcrumbBar>

      {/* <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>

        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>System Users </H1>

          {showDeleteOptions &&
            <NotificationLayer button1Text='Yes' button1Click={deleteUser} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to delete user {userToDelete.name}?</NotificationLayer>}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>

      </Box> */}

      <Box background='white' direction='column' gap='small' round='small'>

        <Box direction='row-responsive' gap='xlarge' justify='between' pad={{ horizontal: 'medium', top: 'medium' }}>
          <Box>
            <Text color='#000' size='large' weight='bold'>System Users</Text>
          </Box>
          <Box direction='row' gap='xsmall'>
            <Button href='user/new/upload' label={<Text><FontAwesomeIcon icon={['fal', 'cloud-upload']} /> Bulk Upload</Text>} secondary />
            <Button href='user/new/edit' label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> Add User</Text>} target='_self' />
          </Box>
        </Box>

        <Box direction='column' gap='medium' pad={{ horizontal: 'medium', bottom: 'small' }}>
          <Box align='center'>
            <PulseLoader
              size={15}
              color='orange'
              loading={loading}
            />
          </Box>

          <ResponsiveContext.Consumer>
            {responsive => (
              <DataTable
                columns={responsive === 'small' ? userColumns.filter(el => (el.property !== 'email' && el.property !== 'created')) : userColumns}
                data={userValues}
                primaryKey='id'
                sort={sort}
                onSort={setSort}
              />)}
          </ResponsiveContext.Consumer>
        </Box>
      </Box>
    </>
  )
}

export default UserList
