import React, { useState } from 'react'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import H2 from '../../components/simple/heading/H2.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function PostIncidentLearning (props) {
  const [questions, setQuestions] = useState(props.questions)

  // Submit form values
  const submitForm = async (values) => {
    props.submitForm(values)
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >
        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Post Incident Learning</H2>
          <Button
            label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
            plain
            tip={{
              content: (
                <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                  <Text>Post incident learning should take place as soon as possible but only when staff and service user are ready.</Text>
                </Box>),
              // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
            }}
          />
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            props.setIncidentValues(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue)
          }}
          value={props.incidentValues}
        >
          <ResponsiveGrid
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['auto', 'auto', 'auto'],
              large: ['auto', 'auto', 'auto'],
              xlarge: ['auto', 'auto', 'auto']
            }}
            gap='small'
          >
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[0].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Recovery behaviours might include:</Text>
                          <Text>Crying</Text>
                          <Text>Apologising</Text>
                          <Text>Defensive postures Body position & tension- arms crossed</Text>
                          <Text>Making personal and offensive remarks </Text>
                          <Text>Changes in eye contact</Text>
                          <Text>Breaking minor rules</Text>
                          <Text>Belligerent and abusive</Text>
                          <Text>Being quiet</Text>
                          <Text>Hiding face in hands</Text>
                          <Text>Pulling down hat</Text>
                          <Text>Tapping</Text>
                          <Text>Withdrawing from group</Text>
                          <Text>Refusing to speak or dismissive</Text>
                          <Text>Refusing to co-operate</Text>
                          <Text>Eye contact increased or decreased</Text>
                          <Text>Changes in breathing</Text>
                        </Box>),
                      // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
                    }}
                  />
                </Box>)}
              name={questions[0].key}
              required
            >
              <TextInput
                name={questions[0].key}
                type='text'
              />
            </FormField>

            <FormField
              label={questions[1].questionText}
              name={questions[1].key}
              required
            >
              <TextInput
                name={questions[1].key}
                type='text'
              />
            </FormField>

            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[2].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Strategies entered in this box will appear as suggested strategies on the individuals Behaviour Plan / Risk Assessment.</Text>
                        </Box>),
                      // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
                    }}
                  />
                </Box>)}
              name={questions[2].key}
              required
            >
              <TextInput
                name={questions[2].key}
                type='text'
              />
            </FormField>
          </ResponsiveGrid>

          <Box>
            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[3].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Staff should listen to the service users point of view without challenge. Staff should use Empathy, rapport, paraphrasing and summarising. to get a clear idea of what happened.</Text>
                        </Box>),
                      // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
                    }}
                  />
                </Box>)}
              name={questions[3].key}
              required
            >
              <TextArea
                name={questions[3].key}
                type='text'
              />
            </FormField>

            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[4].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>Staff should try to ascertain how the trigger made the service user feel.</Text>
                        </Box>),
                      // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
                    }}
                  />
                </Box>)}
              name={questions[4].key}
              required
            >
              <TextArea
                name={questions[4].key}
                type='text'
              />
            </FormField>

            <FormField
              label={(
                <Box fill direction='row' gap='small'>
                  <Text size='small'>{questions[5].questionText}</Text>
                  <Button
                    label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
                    plain
                    tip={{
                      content: (
                        <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                          <Text>The aim of this process is to plan other strategies that can be implemented by the service user and staff the next time the service user feels the way they feel when they experience the trigger.</Text>
                        </Box>),
                      // dropProps: { background: 'red', extend: { border-width: '2px'; border-color: 'blue' } }
                    }}
                  />
                </Box>)}
              name={questions[5].key}
              required
            >
              <TextArea
                name={questions[5].key}
                type='text'
              />
            </FormField>

            <FormField
              label={questions[6].questionText}
              name={questions[6].key}
              required
            >
              <TextArea
                name={questions[6].key}
                type='text'
              />
            </FormField>

            <FormField
              label={questions[7].questionText}
              name={questions[7].key}
            >
              <TextArea
                name={questions[7].key}
              />
            </FormField>
          </Box>
        </Form>
      </Box>
    </Box>
  )
}

export default PostIncidentLearning
